/*
 * Theme definitions
 */
@import url("https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap");
$default_font:"Open Sans", sans-serif;
@mixin for-phone-only {
    @media (max-width: 600) { @content; }
  }
  @mixin for-tablet-portrait {
    @media (max-width: 959px) { @content; }
  }
  @mixin for-tablet-landscape {
    @media (max-width: 1024px) { @content; }
  }
  @mixin for-desktop {
    @media (max-width: 1280px) { @content; }
  }
  @mixin for-big-desktop-up {
    @media (min-width: 1280px) { @content; }
  }
$themes: (
    hnonline: (
        /**colors**/ 
        link_color: #007fa3,
        white_color: #fff,
        black_color: #000000,
        error_color: #d0021b,     
        primary_color: #047a9c,
        primary_color_hover : #036D8C,
        text_color: #252525,
        grey_color: #4e4e4e,
        tutor_theme_color: #d9e6f1,
        /**input-field**/
        input_field_border: #c7c7c7,
        input_field__focus_border: #007fa3,
        link_color_login: #007fa3,
        label_text: #252525,
        login_background: #d9e6f1, 
        green_color: #66be3e,
        grey_color_text: #898989,
        primary_color_lighter: rgba(4, 122, 156, 0.2),
        header_border: #e4e8f1,
        dropdown_shadow_color: #e5e7ee,
        grey_shadow_color: rgba(36, 36, 36, 0.2),
        black_shadow_color: rgba(0, 0, 0, 0.27),
        unitno_color: #a9a9a9,
        heading_color: #00799e,
        /** walkThrough **/ walkThrough_background: #ffffff,
        walkThrough_next: #00799e,
        walkThrough_title: #252525,
        walkThrough_page: #6a7070,
        walkThrough_arrow: #00799e,
        courses_bg: #d2eae4,
        classrooms_bg: #d9e6f1,
        tutor_courses_bg:#d9e6f1,
        progress_bg: #d9e6f1,
        // show hide component
        button_text:#6a7070,

        /** Model Response component **/ 
        background_color:rgba(30,73,108, 0.05),
        white_light: rgba(255,255,255, 0.4),
        hdrow_text: #4a4a4a,
        
        //tabs
        tab_selected: #f5f5f5,
        tab_border: #e4e8f1,
        back_link_color: #00799e,

        /** common popup **/ 
        cancel_button_color:#a9a9a9,

        /** Common-like component**/ 
        like_border_color: #a9a9a9,
        like_text_color: #6a7070,
        like_background_color: #d2eae4,

        /** Response-Box **/
        response_box_left_border: #00799e,
        response_box_avtar_bg: #c8dfe6,
        response_box_avtar_text: #fff,
        response_box_action: #6a7070,
        response_box_view_all :#00799e,
        response_box_divider: #f5f5f5,
        
        // notification bar colors
        notification_bar_bg: #e3eff2,
        notification_checkbox: #00799e,
        notification_shadow: rgba(184, 184, 184, 0.41),
        notification_border: #c8dfe6,
        notification_scroll: #00799e,
        notification_time: #252525,

        // view-all response colors
        response_link_color: #00799e,
        title_2_color: #a9a9a9,

        // journal UI colos
        select_input_border: #4e4e4e87,
        manage_botton_border: #fafafa,
        dropdown_hover_color: #d2eae4,
        // new journal note
        journal_note_input_border:#C7C7C7,
        user_role_color:#f9b903,
        //classrooms color codes
        dropdown_border: #c7c7c7,
        // classroom student detail page
        student_page_background: #d9e6f1,
        student_detail_avtar: #2a84a3,
        student_detail_background_mobile: #c5c5c59e,
        student_detail_title: #cecece,
        classes_link_color: #1e496c,
        progress_text_color: #895b9a,
        progress_bar_color: #DBCDE0,
        table_empty_message_background:#ffffff,
        table_empty_message_text:#4a4a4a,
        activity_footer_color:#4a4a4a,
        // Progress => Unit report
        unitreport_label_color: #4a4a4a,
        averagetime_text_color: #895b9a,
        success_green: #81C36C,
        table_header_bg_color:#e5f2f6,
        table_row_bottom_border:#00000029,
        discussion_list_title_bottom:#6a707033
    ),
    pulse:(
/**colors**/ link_color: #007fa3,
    white_color: #fff,
    error_color: #db0020,
    input_focus: #f5f5f5,
    orange_color: #2600ff,
    btn_cancel_hover_color: #c7c7c7,
    password_medium_input: #ee936f,
    password_stronger_input: #008638,
    heading_color: #252525,
    sub_heading_color: #4e4e4e,
    h3_color: #000,
    h6_color: #5d5d5d,
    /**button component**/ btn_outlined_hover: #f09f4c,
    btn_cancel_background_color: #e9e9e9,
    btn_cancel_color: #252525,
    /**input-field**/ input_field__focus_border: #007fa3,
    /*check-box*/ checkbox_label_disabled_color: #b8b8b8,
    checkbox_label_disabled_color_before: #ddd,
    checkbox_label_disabled_color_after: #6a7070,
    /*add-course*/ addcourse_form_area_shadow: #e5e7ee,
    addcourse_edit_section_border_right: #e6e6e6,
    addcourse_edit_section_color: #007fa3,
    addcourse_edit_section_color_1: #898989,
    addcourse_edit_section_color_2: #252525,
    addcourse_edit_section_color_3: #4e4e4e,
    addcourse_edit_section_color_5: #007ea5,
    addcourse_edit_section_color_6: #979797,
    addcourse_mybar_color: #84bd00,
    addcourse_btnprimary_border_color: #007bff
    )
);
