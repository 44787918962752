@import "../../assets/styles/mixins";

.wrapper {
  @include themify($themes) {
    @include DynamicFontConfig(normal, normal, normal);
    color: themed('text_color');
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    @include for-tablet-landscape {
      display: block;
  }
    &__content{
      background: themed('login_background');
      display: flex;
      flex: 1;
      @include for-tablet-landscape {
        min-height: calc(100% - 60px);
      }
    }
  }
}