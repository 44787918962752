body {
  margin: 0;
  height: 100%;
}
html {
  height: 100%;
}
#root {
  height: 100%
}
input[type='submit'], input[type='button']{
  -webkit-appearance: none;
  border-radius: 0;
}