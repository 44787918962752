@import "../../assets/styles/mixins";

.loader-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    position: absolute;
    border-radius: 0px;
    .MuiLinearProgress-root{
        width: 100%
    }
}

.loader-main-container{
    width: 600px;
    height: 250px;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    align-items: center;
}

.loader-description-items{
    font-size: 20px !important;
    font-family: "Open Sans", sans-serif;
}

.loader-box{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 30px 10% 0px 10%;
    .box{
        width: 25px;
        height: 25px;
        background: #D9E6F1 0% 0% no-repeat padding-box;
        opacity: 1;
    }
}

.fadeOut{
    opacity:0 !important;
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;

}
.fadeIn{
    opacity:1 !important;
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;

}