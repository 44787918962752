@import "../../assets/styles/mixins";

h3 {
  @include themify($themes) {
    font-size: 28px;
    font-weight: 600;
    margin: 0;
    @include for-tablet-landscape {
      font-size: 21px;
    }
    &.black{
        color: themed('black_color')
    }
  }
}

h4 {
  @include themify($themes) {
    font-size: 21px;
    font-weight: normal;
    margin: 0;
    @include for-tablet-landscape {
      font-size: 16px;
    }
    &.black{
        color: themed('black_color')
    }
  }
}

h5 {
  @include themify($themes) {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    &.black{
        color: themed('black_color')
    }
    &.bold{
      font-weight: 600;
    }
  }
}

h6 {
  @include themify($themes) {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
    &.black{
        color: themed('black_color')
    }
    &.bold{
      font-weight: 600;
    }
  }
}

p {
  @include themify($themes) {
    font-size: 12px;
    font-weight: normal;
    margin: 0;
    &.black{
        color: themed('black_color')
    }
    &.grey{
      color: themed('black_color');
      opacity: 0.5;
  }
    &.bold{
      font-weight: 600;
    }
  }
}