@import "../../assets/styles/mixins";
.discussion{
    @include themify($themes) { 
    display: flex;
    width: 100%;
    height: 100%;
    background: #d9e6f1;
    &_container{
        width: 970px;
        margin: 50px auto;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        @include for-big-desktop-up {
          width: 85%;
        }
        @include for-tablet-landscape {
          width: 761px;
        }
        @include for-tablet-portrait {
          width: 90%;
        }
		
		&_discidcontainer{
			width: 670px;
		}
		&_lobcontainer{
			width: 200px;
			margin-left: 767px;
			margin-top: -40px;
			font-size: 12px; 
		}

            .text-area{
                @include DynamicFontConfig(normal, normal, normal);
                background: #fff;
                border: 1px solid #c7c7c7;
                border-radius: 4px;
                padding: 9px 12px;
                font-size: 14px;
                line-height: 18px;
                color: #252525;
                box-sizing: border-box;
                height: 150px;
                &-error{
                    border: 1px solid themed("error_color") !important;
                }
            }
            &_section {
                &_label{
                    width: 620px;
                    font-family: $default_font;
                    font-size: 18px;
                    line-height: 1;
                    margin-bottom: 30px;@include for-tablet-portrait {
                        width: 100%;
                    }

                }
                &_title {
                    display: flex;
                    flex-direction: column;
                    @include for-tablet-portrait {
                        flex-direction: column;
                        width: 100%;
                    }
                    
                    .inputtitle{
                        width: 100%;
                        @include for-tablet-portrait {
                            width: 100%;
                            margin-right: unset;
                        }
                    }
                    
                    .smallinput {
                        width: 100%;
                        // margin-right: 20px;
                        @include for-tablet-portrait {
                            width: 100%;
                            margin-right: unset;
                        }
                    }
                }
                .inputsection{
                    width: 100%;
                    margin-bottom: 20px;
                }
                &_stimulus {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    @include for-tablet-portrait {
                        width: 100%;
                    }
                }
                &_prompt{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    @include for-tablet-portrait {
                        width: 100%;
                    }
                }
                &_answer{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    @include for-tablet-portrait {
                        width: 100%;
                    }
                }
                &_save{
                    display: flex;
                    margin-bottom: 20px;
                    justify-content: space-between;
                    width: 100%;
                    margin-top: 30px;
                    @include for-tablet-portrait {
                        width: 100%;
                    }
                    .button:nth-child(2){
                        margin-left: auto;
                        margin-right: 12px;
                        @include for-tablet-portrait {
                            width: 100%;
                            margin: 10px 0px;
                          }
                    }
                }
            }
        }
    }
}

