@import "../../assets/styles/mixins";

.header {
  @include themify($themes) {
    padding: 0px 55px;
    height: 70px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px themed("header_border");
    @include for-tablet-landscape {
      height: 60px;
      padding: 0 52px;
    }
    @include for-tablet-portrait {
      justify-content: flex-start;
      padding: 0 8px 0 19px;
    }
    &_right {
      display: flex;
      align-self: flex-start;
      align-items: center;
      padding-top: 17px;
      min-width: 265px;

      &__user-menu {
        z-index: 9999;
      }
      &__user-pointer{
        pointer-events: none;
      }
      @include for-tablet-landscape {
        padding-top: 12px;
        min-width: auto;
      }
      @include for-tablet-portrait {
        margin-left: auto;
        min-width: auto;
      }
      &_help {
        margin: 0 23px 0 15px;
        @include for-tablet-portrait {
          margin: 0 10px;
        }
      }
    }

    &__user-menu {
      z-index: 9999;
    }
  }
}
