@import "../../assets/styles/mixins";

.error-container{
  margin-top: 4px;
    &__text{
    @include themify($themes) {
      color:themed('error_color');
      padding-bottom: 7px;
      font-size: 12px;
      display: flex;
      align-items: center;
      &_image {
        height: 16px;
        width: 16px;
        margin-right: 5px;
        margin-left: 1px;
      }
    }
  }
}