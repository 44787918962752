@import "../../assets/styles/mixins";
.btn-primary {
  @include themify($themes) {   
  border-radius: 5px;
  border: solid 1px themed('primary_color');
  background: themed('primary_color');
  color: themed('white_color');
  height: 40px;
  line-height: 17px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 25px;
  min-width: 128px;
  cursor: pointer;
  @include for-tablet-landscape {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px
}
  &:focus {
    outline: 0;
  }
  &:hover {
      background-color: themed('primary_color');
      color: themed('white_color');
  }
  &:disabled{
      opacity: 0.5;
  }
}
}
.grey-outline{
  @include themify($themes) {   
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px themed('label_text');
  background: themed('white_color');
  color: themed('label_text');
  cursor: pointer;
  @include for-tablet-landscape {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px
    }
    &:disabled{
      opacity: 0.5;
    }
  }
}

.grey-bg-outline{
  @include themify($themes) {   
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px themed('label_text');
  background: none;
  color: themed('label_text');
  cursor: pointer;
  @include for-tablet-landscape {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px
  }
  &:disabled{
    opacity: 0.5;
  }
  }
}