@import url("https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap");
@import './theming.scss';


@mixin button() {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin  DynamicFontConfig($fontweight, $fontstyle, $lineheight) {
	font-family: $default_font;
	font-weight: $fontweight;
	font-style: $fontstyle;
	line-height: $lineheight;
}

@mixin whiteBox() {
	border-radius: 5px;  
    background-color: #ffffff;
}


  /*
  * Implementation of themes
  */
  @mixin themify($themes) {
	@each $theme, $map in $themes {
	  .theme-#{$theme} & {
		$theme-map: () !global;
		@each $key, $submap in $map {
		  $value: map-get(map-get($themes, $theme), '#{$key}');
		  $theme-map: map-merge($theme-map, ($key: $value)) !global;
		}
		@content;
		$theme-map: null !global;
	  }
	}
  }

  @function themed($key) {
	@return map-get($theme-map, $key);
  }
  

// Responsive UI media screens
	@mixin for-phone-only {
	 @media screen and (max-width:600px){
		@content; 
	 }
	}

	@mixin for-phone-only-sm {
		@media screen and (max-width: 380) { @content; }
	}

	@mixin for-mobile-devices {
		@media only screen 
		and (max-width : 375px) 
		and (max-height : 812px)
		and (-webkit-device-pixel-ratio : 3) {  @content; }
	}

	@mixin  for-mobile-devices-sm {
		@media only screen and (min-width: 320px) and (max-width: 568px) 
		and (-webkit-device-pixel-ratio: 2){ @content; }
	}

	@mixin for-tablet-portrait {
		@media (max-width: 959px)  { @content; }
	}
	@mixin for-tablet-landscape {
		@media (max-width: 1160px) { @content; }
	}
	@mixin for-desktop {
		@media  (max-width: 1280px) { @content; }
	}
	@mixin for-desktop-sm {
		@media (min-width: 1280px) and (max-width:1366px) { @content; }
	}
	@mixin for-big-desktop-up {
		@media (min-width: 1800px) { @content; }
	}