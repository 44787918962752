@import "../../assets/styles/mixins";
.logo_wrap {
    @include themify($themes) {
        max-width: 250px;
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        img {
            margin-top: 6px;
            @include for-tablet-landscape {
                max-width: 192px;
                margin-top: 6px;
            }
            @include for-tablet-portrait {
                margin-top: 2px;
            }
        }
        &_logo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            span {
                font-size: 16px;
                font-weight: Bold;
                font-family: $default_font;
                letter-spacing: 0px;
                color: #000000;
                margin-left: 10px;
            }
        }
        &__text {
            @include DynamicFontConfig(normal, normal, normal);
            font-size: 16px;
            font-weight: bold;
            padding-top: 11px;
            padding-left: 8px;
            color: themed('black_color');
            text-decoration: none;
        }
    }
}