@import "../../assets/styles/mixins";

.input-box {
  @include themify($themes) {
    @include DynamicFontConfig(normal, normal, normal);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .inputvalue{
      font-size: 16px;
      margin-top: 9px;
      @include for-tablet-portrait {
        margin-top: 3px;
    }
    }
    &__label {
      color: themed("label_text");
      font-size: 12px;
      margin-bottom: 4px;
    }
    &__field {
      box-sizing: unset;
      background: themed("white_color");
      border: 1px solid themed("input_field_border");
      border-radius: 4px;
      padding: 9px 12px;
      height: 36px;
      font-size: 14px;
      line-height: 18px;
      color: themed("text_color");
      box-sizing: border-box;
      &:disabled{
        color:#C7C7C7 ;
      }
      &--container {
        display: flex;
        flex-direction: column;
        position: relative;
      }
      &:focus {
        background: themed("input_focus");
        outline: 0;
        box-shadow: 
          0 0 0 2px themed("white_color"),
          0 0 0 4px themed("input_field__focus_border");
      }
      &--error {
        border: 1px solid themed("error_color") !important;;
        &:focus {
          background: themed("input_focus");
          outline: 0;
          box-shadow: none
        }
      }
    }
  }
}
