@import "../../assets/styles/mixins";

.search-bar {
  @include for-phone-only{
    margin:0;
    width:100%;
  }

  @include themify($themes) {
    &-box {
      border: 0.5px solid #C7C7C7;
      background: themed("white_color");
      width: 450px;
      height: 36px;
      border-radius: 4px;
      @include for-tablet-portrait{
        width: 250px;
      }
     .input-container{
       width: 100%;
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       align-items: center;
       svg{
         margin-right: 10px;
         color: #007FA3;
       }
     }
      input {
        border: 0px;
        height: 34px;
        padding-left: 10px;
        font-size: 14px;
        line-height: 18px;
        color: themed("text_color");
        width: 90%;
        &:focus {
          outline: 0;
        }
      }
    }
  }
}
