@import "../../assets/styles/mixins";
.discussion-preview {
    @include themify($themes) {
        display: flex;
        width: 100%;
        height: 100%;
        background: #d9e6f1;
        &_container {
            width: 970px;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            @include for-big-desktop-up {
                width: 85%;
            }
            @include for-tablet-landscape {
                width: 761px;
            }
            @include for-tablet-portrait {
                width: 90%;
            }
            &_title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #6A7070;
                margin-bottom: 20px;
                .title {
                    text-align: left;
                    font-size: 16px;
                    line-height: 24px;
                    font-family: $default_font;
                    letter-spacing: 0px;
                    color: #000000;
                    padding-bottom: 20px;
                }
            }
            &_html{
                // max-width: 570px;
                width: 70%;
                margin: 0 auto;
            }
            .errormsg {
                font-size: 12px;
                color: red;
                padding: 3px;
            }
            .buttons {
                display: flex;
                padding-top: 30px;
                flex-wrap: wrap;
                width: 100%;
                @include for-tablet-portrait {
                    width: 100%;
                }
                .button {
                    @include for-tablet-portrait {
                        width: 100%;
                        margin: 10px 0px;
                    }
                }
                .button:nth-child(2) {
                    margin-left: auto;
                    margin-right: 12px;
                    @include for-tablet-portrait {
                        width: 100%;
                        margin: 10px 0px;
                    }
                }
            }

            .disc_wrapper{
                display: flex;
                flex-direction: column;
                background-color: themed('white_color');
                padding: 57px 130px;
                @include for-tablet-portrait {
                    padding: 10px 20px;

                }
                &_title{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .grey_title{
                        font-size: 12px;
                        font-family: $default_font;
                        font-weight: bold;
                        text-align: center;
                        color: #979797;
                        margin: 0px;
                    }
                    .black_title{
                        font-family: $default_font;
                        font-size: 21px;
                        font-weight: 600;
                        text-align: center;
                        color: #000000;
                        margin: 0px;
                    }
                    .progress_cont{
                        width: 100%;
                        progress{
                            width: 100%;
                        }
                        progress[value] {
                            height: 3px;
                            border-radius: 3.5px;
                            background-color: #f5f5f5;
                        }
                    }
                }
                &_case_study{
                    .title{
                        font-family: $default_font;
                        font-size: 16px;
                        font-weight: bold;
                        color: #003057;
                        display : none; 
                        overflow-wrap: break-word; 
                        max-width : 100%;
                    }
                    .heading{
                        font-family: $default_font;
                        font-size: 21px;
                        font-weight: 600;
                        color: #000000;
                        display : block; 
                        overflow-wrap: break-word; 
                        max-width : 100%;
                    }
                    .detail{
                        font-family: $default_font;
                        font-size: 16px;
                        margin: 0px;
                        line-height: 1.56;
                        color: #000000;
                        display : block; 
                        overflow-wrap: break-word; 
                        max-width : 100%;
                    }
                    pre {
                        overflow-x: auto;
                        white-space: pre-wrap;
                        white-space: -moz-pre-wrap;
                        white-space: -pre-wrap;
                        white-space: -o-pre-wrap;
                        word-wrap: break-word;
                     }
                }
                &_input{
                    pre {
                        overflow-x: auto;
                        white-space: pre-wrap;
                        white-space: -moz-pre-wrap;
                        white-space: -pre-wrap;
                        white-space: -o-pre-wrap;
                        word-wrap: break-word;
                     }
                    margin-top: 50px;
                    .input_title{
                        font-family: $default_font;
                        font-size: 16px;
                        font-weight: 600;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #000000;
                    }
                        .textarea{  
                            @include DynamicFontConfig(normal, normal, normal);
                            width: 100%;
                            padding:20px 30px;
                            border-radius: 5px;
                            background-color: #fff;
                            border: 1;
                            min-height: 160px;
                            resize: none;
                            font-family: $default_font;
                            font-size: 16px;
                        }
                    .title_length{
                        width: 100%;
                        display: flex;
                        justify-content: flex-end;
                        font-family: $default_font;
                        font-size: 14px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: italic;
                        line-height: normal;
                        letter-spacing: normal;
                        color: #6a7070;
                    }
                }
            }
        }
    }
}