@import "../../assets/styles/mixins";

.icon {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    margin-left: 5px;
  }
.arrow {
@include themify($themes) {
    border: solid  themed('walkThrough_icon');;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 4.5px;
}
}

.show-hide-container{
    @include themify($themes) {
        // border: red solid 4px;
        &_button{
            color: themed('button_text');
        }
        &_arrow{
            border: solid themed('button_text');
            border-width: 0px 1.5px 1.5px 0;
            display: inline-block;
            padding: 4.1px;
            &_down{
                transform: rotate(-45deg);
                -webkit-transform: rotate(45deg);
                margin-left: 12px;            
            }
            &_up{
                transform: rotate(-45deg);
                -webkit-transform: rotate(45deg);
                margin-left: 12px;
                border-width: 1.5px 0px 0px 1.5px !important;        
            }
        }
    }
}