@import "../../assets/styles/mixins";
.discussionURL {
    @include themify($themes) {
        display: flex;
        width: 100%;
        height: 100%;
        background: #d9e6f1;
        &_container {
            width: 970px;
            margin: 50px auto;
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            @include for-big-desktop-up {
                width: 85%;
            }
            @include for-tablet-landscape {
                width: 761px;
            }
            @include for-tablet-portrait {
                width: 90%;
            }
        }
        &_row {
            display: flex;
            width: 100%;
            flex-direction: column;
            @include for-tablet-portrait {
                flex-wrap: wrap;
            }
            .discussionURL_id{
                width: 100%;
            }
        }
        .url_input {
            color: #C7C7C7;
            cursor: default;
            &:focus {
                box-shadow: none;
            }
        }
        .url_input2 {
            color: #000000;
            cursor: default;
            &:focus {
                box-shadow: none;
            }
        }

        &_input {
            width: 100%;
            display: flex;
            flex-direction: row;
            @include for-tablet-portrait {
                width: 100%;
            }
            .input-box{
                width: 100%;
            }
        }
        &_id {
            width: 100px;
            margin-right: 5px;
            @include for-tablet-landscape {
                width: 10%;
            }
            @include for-tablet-portrait {
                width: 100%;
            }
        }
        .successmsg {
            background: themed('white_color');
            box-shadow: 0 6px 10px -4px themed('black_shadow_color');
            border-left: 4px solid themed('success_green');
            padding: 15px 25px;
            font-size: 14px;
            color: themed('text_color');
            font-weight: bold;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px
            }
        }
        .buttons {
            display: flex;
            padding-top: 15px;
            flex-wrap: wrap;
            @include for-tablet-portrait {
                width: 100%;
                padding-top: 0px;
            }
            .button {
                margin-left: 24px;
                @include for-tablet-landscape {
                    margin-left: 10px;
                }
                @include for-tablet-portrait {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 10px
                }
            }
        }
        .listinglink {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            @include for-tablet-portrait {
                flex-wrap: wrap;
            }
            .button {
                margin-left: 12px;
                @include for-tablet-portrait {
                    width: 100%;
                    margin-left: 0px;
                    margin-top: 10px
                }
            }
        }
        .viewlink {
            color: #047A9C;
            font-size: 14px;
        }
    }
}