@import "../../assets/styles/mixins";

.model-container{
    @include themify($themes){
        width: 100%;
        border-radius: 5px;
        background-color:themed('background_color');
        padding: 38px 40px 40px 41px;
        @include for-tablet-portrait {
            padding: 20px;
        }
        &-title{
            font-size: 14px;
            font-family:  $default_font;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 14px;
            text-transform: uppercase;
            .arrow-icon {
                display: none;
                @include for-tablet-portrait {
                    display: block;
                    color: themed('walkThrough_arrow');
                }
            }
        }
        &-description{
            font-family:  $default_font;
            font-size: 16px;
            line-height: 1.56;
            margin: 10px 0px 0px 0px;
            .typography{
                margin: 0px;
            }
            .MuiCardContent-root{
                padding: 0px;
            }
        }
    }
}