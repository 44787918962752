@import "../../assets/styles/mixins";
.discussion-list{
    @include themify($themes) { 
    display: flex;
    width: 100%;
    height: 100%;
    background: themed('student_page_background');
        &_container{
            display: flex;
            flex-direction: column;
            width: 100%;
            margin: 55px 155px 88px 155px;
            background-color: themed('white_color');
            padding: opx 0px 24px 0px;
            border-radius: 10px;
            @include for-tablet-portrait {
                margin: 20px 10px 0px 10px;
            }
            &_title{
                height: 60px;
                border-bottom: 1px solid themed('discussion_list_title_bottom');
                padding: 0px 24px 0px 24px;
                p{
                    font-size: 18px;
                    letter-spacing: 0px;
                    font-family: $default_font;
                    color: #007FA3;
                    padding: 20px 0;
                }
            }
            &_button{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 22px;
                padding: 0px 24px 0px 24px;
                .button{
                    display: flex;
                    width: 128px;
                    height: 32px;
                    background: #047A9C 0% 0% no-repeat padding-box;
                    border-radius: 50px;
                    opacity: 1;
                    align-items: center;
                    justify-content: center;
                    color: themed('white_color');
                    font-size: 14px;
                    padding: 0px;
                }
            }
            &_table{
                display: flex;
                margin-top: 20px;
            }
        }
    }
}

