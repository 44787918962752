@import "../../assets/styles/mixins";

.dropdown-container {
  @include themify($themes) {
    width: 100%;
    display: flex;
    flex-direction: column;
    &-label {
      padding: 2px 4px;
      color: themed("label_text");
    }
    &-inputbox {
      border: 1px solid themed("dropdown_border");
      background-color: themed("white_color");
      width: 100%;
      height: 36px;
      font-size: 12px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;      
      &-nav {
        color: themed("text_color");
        font-size: 12px;
        margin: 45px 0 0 0;
        @include for-phone-only {
          margin: 50px 0 0 2px;
        }
        ul {
          padding:8px;
        }
        li {
          font-size: inherit;
        }
       li:hover {
          background-color: themed("tutor_theme_color");
        }
        .dd-selected-menu
        {
          background-color:themed("tutor_theme_color");
		  font-size: 12px;
        }
      }
      &-input {
        width: 100%;
        align-items: center;
        height: 26px;
        text-indent: 10px;
        padding: 8px 0 0 0;
        margin: 0;
        cursor: pointer;
        justify-content: space-between;
        @include for-tablet-portrait {
          width: 100%;
        }
        &:focus {
          background-color: transparent;
          outline: -webkit-focus-ring-color auto 5px;
        }
      }
      .dd-disabled {
        background-color: themed("tutor_theme_color");
        height: 26px;
        text-indent: 10px;
        padding: 8px 0 0 0;
        margin: 0;
        opacity: 0.6;
        color: themed("text_color");
        border: 0;
        border-radius: 5px;
      }
    }
    @include for-phone-only {
      width: 100%;
    }
   
  }
}
