@import "../../assets/styles/mixins";
.table-container {
  @include themify($themes) {   
    display: flex;
    flex-direction: column;
    width: 100%;
    &_header{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      background-color: themed('table_header_bg_color');
      height: 44px;
      border-radius: 5px 5px 0px 0px;
      padding-left: 48px;
      @include for-tablet-portrait {
        padding-left: 10px;
      }
      .head{
        width: 100%;
        font-size: 14px;
        font-weight: bold;
      }
      .last_head{
        width: 50%;
      }
    }
    &_body{
      display: flex;
      flex-direction: column;
      &_row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        background-color: themed('white_color');
        height: 43px;
        align-items: center;
        border-bottom: 1px solid themed('table_row_bottom_border');
        padding-left: 48px;
        @include for-tablet-portrait {
          padding-left: 10px;
        }
        .cell{
          width: 100%;
          padding-right: 20px;
        }
        .last_cell{
          width: 50%;
        }
      }
      .last_row{
        border-radius: 0px 0px 5px 5px;
      }
    }
    
    .edit_button{
      color: #217a9c;
      text-decoration: underline;
      cursor: pointer;
    }
    .page-icon{
      color: 'red';
      border-radius: 50%;
      border: 1px solid;
    }
    .MuiPaper-root{
      border-radius: 10px !important;
    }
    .MuiTableCell-root span svg {
      opacity: 1;
    }
    .MuiTableSortLabel-root.MuiTableSortLabel-active {
      font-weight: bold;
    }
    .MuiPaper-elevation2{
      box-shadow: unset !important;
    }
    .MuiTableRow-head {
      border-top: 1px solid #00000029;
    }
    .bottom-text{
      width: 100%;
      font-size: 16px;
      font-family: $default_font;
    }
    .MuiTableFooter-root tr{
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-left: 24px;
    }
    .MuiTablePagination-input{
      display: none;
    }
  }
}
