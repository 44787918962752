@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:100, 200,300, 400,600,700&display=swap);
body {
  margin: 0;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

input[type=submit], input[type=button] {
  -webkit-appearance: none;
  border-radius: 0;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: absolute;
  border-radius: 0px;
}
.loader-container .MuiLinearProgress-root {
  width: 100%;
}

.loader-main-container {
  width: 600px;
  height: 250px;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
}

.loader-description-items {
  font-size: 20px !important;
  font-family: "Open Sans", sans-serif;
}

.loader-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 30px 10% 0px 10%;
}
.loader-box .box {
  width: 25px;
  height: 25px;
  background: #D9E6F1 0% 0% no-repeat padding-box;
  opacity: 1;
}

.fadeOut {
  opacity: 0 !important;
  transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
}

.fadeIn {
  opacity: 1 !important;
  transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .input-box {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.theme-hnonline .input-box .inputvalue {
  font-size: 16px;
  margin-top: 9px;
}
@media (max-width: 959px) {
  .theme-hnonline .input-box .inputvalue {
    margin-top: 3px;
  }
}
.theme-hnonline .input-box__label {
  color: #252525;
  font-size: 12px;
  margin-bottom: 4px;
}
.theme-hnonline .input-box__field {
  box-sizing: unset;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 9px 12px;
  height: 36px;
  font-size: 14px;
  line-height: 18px;
  color: #252525;
  box-sizing: border-box;
}
.theme-hnonline .input-box__field:disabled {
  color: #C7C7C7;
}
.theme-hnonline .input-box__field--container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.theme-hnonline .input-box__field:focus {
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #007fa3;
}
.theme-hnonline .input-box__field--error {
  border: 1px solid #d0021b !important;
}
.theme-hnonline .input-box__field--error:focus {
  outline: 0;
  box-shadow: none;
}
.theme-pulse .input-box {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.theme-pulse .input-box .inputvalue {
  font-size: 16px;
  margin-top: 9px;
}
@media (max-width: 959px) {
  .theme-pulse .input-box .inputvalue {
    margin-top: 3px;
  }
}
.theme-pulse .input-box__label {
  font-size: 12px;
  margin-bottom: 4px;
}
.theme-pulse .input-box__field {
  box-sizing: unset;
  background: #fff;
  border: 1px solid;
  border-radius: 4px;
  padding: 9px 12px;
  height: 36px;
  font-size: 14px;
  line-height: 18px;
  box-sizing: border-box;
}
.theme-pulse .input-box__field:disabled {
  color: #C7C7C7;
}
.theme-pulse .input-box__field--container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.theme-pulse .input-box__field:focus {
  background: #f5f5f5;
  outline: 0;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #007fa3;
}
.theme-pulse .input-box__field--error {
  border: 1px solid #db0020 !important;
}
.theme-pulse .input-box__field--error:focus {
  background: #f5f5f5;
  outline: 0;
  box-shadow: none;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.error-container {
  margin-top: 4px;
}
.theme-hnonline .error-container__text {
  color: #d0021b;
  padding-bottom: 7px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.theme-hnonline .error-container__text_image {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  margin-left: 1px;
}
.theme-pulse .error-container__text {
  color: #db0020;
  padding-bottom: 7px;
  font-size: 12px;
  display: flex;
  align-items: center;
}
.theme-pulse .error-container__text_image {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  margin-left: 1px;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .btn-primary {
  border-radius: 5px;
  border: solid 1px #047a9c;
  background: #047a9c;
  color: #fff;
  height: 40px;
  line-height: 17px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 25px;
  min-width: 128px;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-hnonline .btn-primary {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-hnonline .btn-primary:focus {
  outline: 0;
}
.theme-hnonline .btn-primary:hover {
  background-color: #047a9c;
  color: #fff;
}
.theme-hnonline .btn-primary:disabled {
  opacity: 0.5;
}
.theme-pulse .btn-primary {
  border-radius: 5px;
  border: solid 1px;
  color: #fff;
  height: 40px;
  line-height: 17px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 25px;
  min-width: 128px;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-pulse .btn-primary {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-pulse .btn-primary:focus {
  outline: 0;
}
.theme-pulse .btn-primary:hover {
  color: #fff;
}
.theme-pulse .btn-primary:disabled {
  opacity: 0.5;
}

.theme-hnonline .grey-outline {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px #252525;
  background: #fff;
  color: #252525;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-hnonline .grey-outline {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-hnonline .grey-outline:disabled {
  opacity: 0.5;
}
.theme-pulse .grey-outline {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px;
  background: #fff;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-pulse .grey-outline {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-pulse .grey-outline:disabled {
  opacity: 0.5;
}

.theme-hnonline .grey-bg-outline {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px #252525;
  background: none;
  color: #252525;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-hnonline .grey-bg-outline {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-hnonline .grey-bg-outline:disabled {
  opacity: 0.5;
}
.theme-pulse .grey-bg-outline {
  display: flex;
  justify-content: center;
  height: 40px;
  padding: 7px 15px;
  border-radius: 20px;
  min-width: 128px;
  border: solid 1px;
  background: none;
  cursor: pointer;
}
@media (max-width: 1160px) {
  .theme-pulse .grey-bg-outline {
    min-width: 128px;
    height: 32px;
    padding: 7px 15px;
  }
}
.theme-pulse .grey-bg-outline:disabled {
  opacity: 0.5;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .discussion {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-hnonline .discussion_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 1800px) {
  .theme-hnonline .discussion_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-hnonline .discussion_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container {
    width: 90%;
  }
}
.theme-hnonline .discussion_container_discidcontainer {
  width: 670px;
}
.theme-hnonline .discussion_container_lobcontainer {
  width: 200px;
  margin-left: 767px;
  margin-top: -40px;
  font-size: 12px;
}
.theme-hnonline .discussion_container .text-area {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 14px;
  line-height: 18px;
  color: #252525;
  box-sizing: border-box;
  height: 150px;
}
.theme-hnonline .discussion_container .text-area-error {
  border: 1px solid #d0021b !important;
}
.theme-hnonline .discussion_container_section_label {
  width: 620px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 30px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_label {
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_title {
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_title {
    flex-direction: column;
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_title .inputtitle {
  width: 100%;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_title .inputtitle {
    width: 100%;
    margin-right: unset;
  }
}
.theme-hnonline .discussion_container_section_title .smallinput {
  width: 100%;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_title .smallinput {
    width: 100%;
    margin-right: unset;
  }
}
.theme-hnonline .discussion_container_section .inputsection {
  width: 100%;
  margin-bottom: 20px;
}
.theme-hnonline .discussion_container_section_stimulus {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_stimulus {
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_prompt {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_prompt {
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_answer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_answer {
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_save {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_save {
    width: 100%;
  }
}
.theme-hnonline .discussion_container_section_save .button:nth-child(2) {
  margin-left: auto;
  margin-right: 12px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion_container_section_save .button:nth-child(2) {
    width: 100%;
    margin: 10px 0px;
  }
}
.theme-pulse .discussion {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-pulse .discussion_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 1800px) {
  .theme-pulse .discussion_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-pulse .discussion_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container {
    width: 90%;
  }
}
.theme-pulse .discussion_container_discidcontainer {
  width: 670px;
}
.theme-pulse .discussion_container_lobcontainer {
  width: 200px;
  margin-left: 767px;
  margin-top: -40px;
  font-size: 12px;
}
.theme-pulse .discussion_container .text-area {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  background: #fff;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  padding: 9px 12px;
  font-size: 14px;
  line-height: 18px;
  color: #252525;
  box-sizing: border-box;
  height: 150px;
}
.theme-pulse .discussion_container .text-area-error {
  border: 1px solid #db0020 !important;
}
.theme-pulse .discussion_container_section_label {
  width: 620px;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 30px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_label {
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_title {
  display: flex;
  flex-direction: column;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_title {
    flex-direction: column;
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_title .inputtitle {
  width: 100%;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_title .inputtitle {
    width: 100%;
    margin-right: unset;
  }
}
.theme-pulse .discussion_container_section_title .smallinput {
  width: 100%;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_title .smallinput {
    width: 100%;
    margin-right: unset;
  }
}
.theme-pulse .discussion_container_section .inputsection {
  width: 100%;
  margin-bottom: 20px;
}
.theme-pulse .discussion_container_section_stimulus {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_stimulus {
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_prompt {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_prompt {
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_answer {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_answer {
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_save {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_save {
    width: 100%;
  }
}
.theme-pulse .discussion_container_section_save .button:nth-child(2) {
  margin-left: auto;
  margin-right: 12px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion_container_section_save .button:nth-child(2) {
    width: 100%;
    margin: 10px 0px;
  }
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.theme-hnonline .dropdown-container-label {
  padding: 2px 4px;
  color: #252525;
}
.theme-hnonline .dropdown-container-inputbox {
  border: 1px solid #c7c7c7;
  background-color: #fff;
  width: 100%;
  height: 36px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.theme-hnonline .dropdown-container-inputbox-nav {
  color: #252525;
  font-size: 12px;
  margin: 45px 0 0 0;
}
@media screen and (max-width: 600px) {
  .theme-hnonline .dropdown-container-inputbox-nav {
    margin: 50px 0 0 2px;
  }
}
.theme-hnonline .dropdown-container-inputbox-nav ul {
  padding: 8px;
}
.theme-hnonline .dropdown-container-inputbox-nav li {
  font-size: inherit;
}
.theme-hnonline .dropdown-container-inputbox-nav li:hover {
  background-color: #d9e6f1;
}
.theme-hnonline .dropdown-container-inputbox-nav .dd-selected-menu {
  background-color: #d9e6f1;
  font-size: 12px;
}
.theme-hnonline .dropdown-container-inputbox-input {
  width: 100%;
  align-items: center;
  height: 26px;
  text-indent: 10px;
  padding: 8px 0 0 0;
  margin: 0;
  cursor: pointer;
  justify-content: space-between;
}
@media (max-width: 959px) {
  .theme-hnonline .dropdown-container-inputbox-input {
    width: 100%;
  }
}
.theme-hnonline .dropdown-container-inputbox-input:focus {
  background-color: transparent;
  outline: -webkit-focus-ring-color auto 5px;
}
.theme-hnonline .dropdown-container-inputbox .dd-disabled {
  background-color: #d9e6f1;
  height: 26px;
  text-indent: 10px;
  padding: 8px 0 0 0;
  margin: 0;
  opacity: 0.6;
  color: #252525;
  border: 0;
  border-radius: 5px;
}
@media screen and (max-width: 600px) {
  .theme-hnonline .dropdown-container {
    width: 100%;
  }
}
.theme-pulse .dropdown-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.theme-pulse .dropdown-container-label {
  padding: 2px 4px;
}
.theme-pulse .dropdown-container-inputbox {
  border: 1px solid;
  background-color: #fff;
  width: 100%;
  height: 36px;
  font-size: 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.theme-pulse .dropdown-container-inputbox-nav {
  font-size: 12px;
  margin: 45px 0 0 0;
}
@media screen and (max-width: 600px) {
  .theme-pulse .dropdown-container-inputbox-nav {
    margin: 50px 0 0 2px;
  }
}
.theme-pulse .dropdown-container-inputbox-nav ul {
  padding: 8px;
}
.theme-pulse .dropdown-container-inputbox-nav li {
  font-size: inherit;
}
.theme-pulse .dropdown-container-inputbox-nav .dd-selected-menu {
  font-size: 12px;
}
.theme-pulse .dropdown-container-inputbox-input {
  width: 100%;
  align-items: center;
  height: 26px;
  text-indent: 10px;
  padding: 8px 0 0 0;
  margin: 0;
  cursor: pointer;
  justify-content: space-between;
}
@media (max-width: 959px) {
  .theme-pulse .dropdown-container-inputbox-input {
    width: 100%;
  }
}
.theme-pulse .dropdown-container-inputbox-input:focus {
  background-color: transparent;
  outline: -webkit-focus-ring-color auto 5px;
}
.theme-pulse .dropdown-container-inputbox .dd-disabled {
  height: 26px;
  text-indent: 10px;
  padding: 8px 0 0 0;
  margin: 0;
  opacity: 0.6;
  border: 0;
  border-radius: 5px;
}
@media screen and (max-width: 600px) {
  .theme-pulse .dropdown-container {
    width: 100%;
  }
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .discussion-preview {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-hnonline .discussion-preview_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 1800px) {
  .theme-hnonline .discussion-preview_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-hnonline .discussion-preview_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-preview_container {
    width: 90%;
  }
}
.theme-hnonline .discussion-preview_container_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6A7070;
  margin-bottom: 20px;
}
.theme-hnonline .discussion-preview_container_title .title {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 20px;
}
.theme-hnonline .discussion-preview_container_html {
  width: 70%;
  margin: 0 auto;
}
.theme-hnonline .discussion-preview_container .errormsg {
  font-size: 12px;
  color: red;
  padding: 3px;
}
.theme-hnonline .discussion-preview_container .buttons {
  display: flex;
  padding-top: 30px;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-preview_container .buttons {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-preview_container .buttons .button {
    width: 100%;
    margin: 10px 0px;
  }
}
.theme-hnonline .discussion-preview_container .buttons .button:nth-child(2) {
  margin-left: auto;
  margin-right: 12px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-preview_container .buttons .button:nth-child(2) {
    width: 100%;
    margin: 10px 0px;
  }
}
.theme-hnonline .discussion-preview_container .disc_wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 57px 130px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-preview_container .disc_wrapper {
    padding: 10px 20px;
  }
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title .grey_title {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  color: #979797;
  margin: 0px;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title .black_title {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  margin: 0px;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title .progress_cont {
  width: 100%;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title .progress_cont progress {
  width: 100%;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_title .progress_cont progress[value] {
  height: 3px;
  border-radius: 3.5px;
  background-color: #f5f5f5;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_case_study .title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #003057;
  display: none;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_case_study .heading {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  display: block;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_case_study .detail {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0px;
  line-height: 1.56;
  color: #000000;
  display: block;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_case_study pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_input {
  margin-top: 50px;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_input pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_input .input_title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_input .textarea {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  width: 100%;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1;
  min-height: 160px;
  resize: none;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
.theme-hnonline .discussion-preview_container .disc_wrapper_input .title_length {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #6a7070;
}
.theme-pulse .discussion-preview {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-pulse .discussion-preview_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
@media (min-width: 1800px) {
  .theme-pulse .discussion-preview_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-pulse .discussion-preview_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussion-preview_container {
    width: 90%;
  }
}
.theme-pulse .discussion-preview_container_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #6A7070;
  margin-bottom: 20px;
}
.theme-pulse .discussion-preview_container_title .title {
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  padding-bottom: 20px;
}
.theme-pulse .discussion-preview_container_html {
  width: 70%;
  margin: 0 auto;
}
.theme-pulse .discussion-preview_container .errormsg {
  font-size: 12px;
  color: red;
  padding: 3px;
}
.theme-pulse .discussion-preview_container .buttons {
  display: flex;
  padding-top: 30px;
  flex-wrap: wrap;
  width: 100%;
}
@media (max-width: 959px) {
  .theme-pulse .discussion-preview_container .buttons {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussion-preview_container .buttons .button {
    width: 100%;
    margin: 10px 0px;
  }
}
.theme-pulse .discussion-preview_container .buttons .button:nth-child(2) {
  margin-left: auto;
  margin-right: 12px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion-preview_container .buttons .button:nth-child(2) {
    width: 100%;
    margin: 10px 0px;
  }
}
.theme-pulse .discussion-preview_container .disc_wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 57px 130px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion-preview_container .disc_wrapper {
    padding: 10px 20px;
  }
}
.theme-pulse .discussion-preview_container .disc_wrapper_title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.theme-pulse .discussion-preview_container .disc_wrapper_title .grey_title {
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  text-align: center;
  color: #979797;
  margin: 0px;
}
.theme-pulse .discussion-preview_container .disc_wrapper_title .black_title {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  text-align: center;
  color: #000000;
  margin: 0px;
}
.theme-pulse .discussion-preview_container .disc_wrapper_title .progress_cont {
  width: 100%;
}
.theme-pulse .discussion-preview_container .disc_wrapper_title .progress_cont progress {
  width: 100%;
}
.theme-pulse .discussion-preview_container .disc_wrapper_title .progress_cont progress[value] {
  height: 3px;
  border-radius: 3.5px;
  background-color: #f5f5f5;
}
.theme-pulse .discussion-preview_container .disc_wrapper_case_study .title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #003057;
  display: none;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-pulse .discussion-preview_container .disc_wrapper_case_study .heading {
  font-family: "Open Sans", sans-serif;
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  display: block;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-pulse .discussion-preview_container .disc_wrapper_case_study .detail {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin: 0px;
  line-height: 1.56;
  color: #000000;
  display: block;
  overflow-wrap: break-word;
  max-width: 100%;
}
.theme-pulse .discussion-preview_container .disc_wrapper_case_study pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.theme-pulse .discussion-preview_container .disc_wrapper_input {
  margin-top: 50px;
}
.theme-pulse .discussion-preview_container .disc_wrapper_input pre {
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.theme-pulse .discussion-preview_container .disc_wrapper_input .input_title {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.theme-pulse .discussion-preview_container .disc_wrapper_input .textarea {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  width: 100%;
  padding: 20px 30px;
  border-radius: 5px;
  background-color: #fff;
  border: 1;
  min-height: 160px;
  resize: none;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}
.theme-pulse .discussion-preview_container .disc_wrapper_input .title_length {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #6a7070;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .discussionURL {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-hnonline .discussionURL_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (min-width: 1800px) {
  .theme-hnonline .discussionURL_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-hnonline .discussionURL_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL_container {
    width: 90%;
  }
}
.theme-hnonline .discussionURL_row {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL_row {
    flex-wrap: wrap;
  }
}
.theme-hnonline .discussionURL_row .discussionURL_id {
  width: 100%;
}
.theme-hnonline .discussionURL .url_input {
  color: #C7C7C7;
  cursor: default;
}
.theme-hnonline .discussionURL .url_input:focus {
  box-shadow: none;
}
.theme-hnonline .discussionURL .url_input2 {
  color: #000000;
  cursor: default;
}
.theme-hnonline .discussionURL .url_input2:focus {
  box-shadow: none;
}
.theme-hnonline .discussionURL_input {
  width: 100%;
  display: flex;
  flex-direction: row;
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL_input {
    width: 100%;
  }
}
.theme-hnonline .discussionURL_input .input-box {
  width: 100%;
}
.theme-hnonline .discussionURL_id {
  width: 100px;
  margin-right: 5px;
}
@media (max-width: 1160px) {
  .theme-hnonline .discussionURL_id {
    width: 10%;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL_id {
    width: 100%;
  }
}
.theme-hnonline .discussionURL .successmsg {
  background: #fff;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.27);
  border-left: 4px solid #81C36C;
  padding: 15px 25px;
  font-size: 14px;
  color: #252525;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.theme-hnonline .discussionURL .successmsg img {
  margin-right: 10px;
}
.theme-hnonline .discussionURL .buttons {
  display: flex;
  padding-top: 15px;
  flex-wrap: wrap;
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL .buttons {
    width: 100%;
    padding-top: 0px;
  }
}
.theme-hnonline .discussionURL .buttons .button {
  margin-left: 24px;
}
@media (max-width: 1160px) {
  .theme-hnonline .discussionURL .buttons .button {
    margin-left: 10px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL .buttons .button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
.theme-hnonline .discussionURL .listinglink {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL .listinglink {
    flex-wrap: wrap;
  }
}
.theme-hnonline .discussionURL .listinglink .button {
  margin-left: 12px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussionURL .listinglink .button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
.theme-hnonline .discussionURL .viewlink {
  color: #047A9C;
  font-size: 14px;
}
.theme-pulse .discussionURL {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-pulse .discussionURL_container {
  width: 970px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
@media (min-width: 1800px) {
  .theme-pulse .discussionURL_container {
    width: 85%;
  }
}
@media (max-width: 1160px) {
  .theme-pulse .discussionURL_container {
    width: 761px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL_container {
    width: 90%;
  }
}
.theme-pulse .discussionURL_row {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL_row {
    flex-wrap: wrap;
  }
}
.theme-pulse .discussionURL_row .discussionURL_id {
  width: 100%;
}
.theme-pulse .discussionURL .url_input {
  color: #C7C7C7;
  cursor: default;
}
.theme-pulse .discussionURL .url_input:focus {
  box-shadow: none;
}
.theme-pulse .discussionURL .url_input2 {
  color: #000000;
  cursor: default;
}
.theme-pulse .discussionURL .url_input2:focus {
  box-shadow: none;
}
.theme-pulse .discussionURL_input {
  width: 100%;
  display: flex;
  flex-direction: row;
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL_input {
    width: 100%;
  }
}
.theme-pulse .discussionURL_input .input-box {
  width: 100%;
}
.theme-pulse .discussionURL_id {
  width: 100px;
  margin-right: 5px;
}
@media (max-width: 1160px) {
  .theme-pulse .discussionURL_id {
    width: 10%;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL_id {
    width: 100%;
  }
}
.theme-pulse .discussionURL .successmsg {
  background: #fff;
  box-shadow: 0 6px 10px -4px;
  border-left: 4px solid;
  padding: 15px 25px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.theme-pulse .discussionURL .successmsg img {
  margin-right: 10px;
}
.theme-pulse .discussionURL .buttons {
  display: flex;
  padding-top: 15px;
  flex-wrap: wrap;
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL .buttons {
    width: 100%;
    padding-top: 0px;
  }
}
.theme-pulse .discussionURL .buttons .button {
  margin-left: 24px;
}
@media (max-width: 1160px) {
  .theme-pulse .discussionURL .buttons .button {
    margin-left: 10px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL .buttons .button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
.theme-pulse .discussionURL .listinglink {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL .listinglink {
    flex-wrap: wrap;
  }
}
.theme-pulse .discussionURL .listinglink .button {
  margin-left: 12px;
}
@media (max-width: 959px) {
  .theme-pulse .discussionURL .listinglink .button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}
.theme-pulse .discussionURL .viewlink {
  color: #047A9C;
  font-size: 14px;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
@media (max-width: 1160px) {
  .theme-hnonline h3 {
    font-size: 21px;
  }
}
.theme-hnonline h3.black {
  color: #000000;
}
.theme-pulse h3 {
  font-size: 28px;
  font-weight: 600;
  margin: 0;
}
@media (max-width: 1160px) {
  .theme-pulse h3 {
    font-size: 21px;
  }
}
.theme-hnonline h4 {
  font-size: 21px;
  font-weight: normal;
  margin: 0;
}
@media (max-width: 1160px) {
  .theme-hnonline h4 {
    font-size: 16px;
  }
}
.theme-hnonline h4.black {
  color: #000000;
}
.theme-pulse h4 {
  font-size: 21px;
  font-weight: normal;
  margin: 0;
}
@media (max-width: 1160px) {
  .theme-pulse h4 {
    font-size: 16px;
  }
}
.theme-hnonline h5 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
.theme-hnonline h5.black {
  color: #000000;
}
.theme-hnonline h5.bold {
  font-weight: 600;
}
.theme-pulse h5 {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}
.theme-pulse h5.bold {
  font-weight: 600;
}

.theme-hnonline h6 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.theme-hnonline h6.black {
  color: #000000;
}
.theme-hnonline h6.bold {
  font-weight: 600;
}
.theme-pulse h6 {
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}
.theme-pulse h6.bold {
  font-weight: 600;
}

.theme-hnonline p {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}
.theme-hnonline p.black {
  color: #000000;
}
.theme-hnonline p.grey {
  color: #000000;
  opacity: 0.5;
}
.theme-hnonline p.bold {
  font-weight: 600;
}
.theme-pulse p {
  font-size: 12px;
  font-weight: normal;
  margin: 0;
}
.theme-pulse p.grey {
  opacity: 0.5;
}
.theme-pulse p.bold {
  font-weight: 600;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .model-container {
  width: 100%;
  border-radius: 5px;
  background-color: rgba(30, 73, 108, 0.05);
  padding: 38px 40px 40px 41px;
}
@media (max-width: 959px) {
  .theme-hnonline .model-container {
    padding: 20px;
  }
}
.theme-hnonline .model-container-title {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 14px;
  text-transform: uppercase;
}
.theme-hnonline .model-container-title .arrow-icon {
  display: none;
}
@media (max-width: 959px) {
  .theme-hnonline .model-container-title .arrow-icon {
    display: block;
    color: #00799e;
  }
}
.theme-hnonline .model-container-description {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.56;
  margin: 10px 0px 0px 0px;
}
.theme-hnonline .model-container-description .typography {
  margin: 0px;
}
.theme-hnonline .model-container-description .MuiCardContent-root {
  padding: 0px;
}
.theme-pulse .model-container {
  width: 100%;
  border-radius: 5px;
  padding: 38px 40px 40px 41px;
}
@media (max-width: 959px) {
  .theme-pulse .model-container {
    padding: 20px;
  }
}
.theme-pulse .model-container-title {
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 14px;
  text-transform: uppercase;
}
.theme-pulse .model-container-title .arrow-icon {
  display: none;
}
@media (max-width: 959px) {
  .theme-pulse .model-container-title .arrow-icon {
    display: block;
  }
}
.theme-pulse .model-container-description {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.56;
  margin: 10px 0px 0px 0px;
}
.theme-pulse .model-container-description .typography {
  margin: 0px;
}
.theme-pulse .model-container-description .MuiCardContent-root {
  padding: 0px;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.icon {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-left: 5px;
}

.theme-hnonline .arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4.5px;
}
.theme-pulse .arrow {
  border: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4.5px;
}

.theme-hnonline .show-hide-container_button {
  color: #6a7070;
}
.theme-hnonline .show-hide-container_arrow {
  border: solid #6a7070;
  border-width: 0px 1.5px 1.5px 0;
  display: inline-block;
  padding: 4.1px;
}
.theme-hnonline .show-hide-container_arrow_down {
  transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 12px;
}
.theme-hnonline .show-hide-container_arrow_up {
  transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 12px;
  border-width: 1.5px 0px 0px 1.5px !important;
}
.theme-pulse .show-hide-container_arrow {
  border: solid;
  border-width: 0px 1.5px 1.5px 0;
  display: inline-block;
  padding: 4.1px;
}
.theme-pulse .show-hide-container_arrow_down {
  transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 12px;
}
.theme-pulse .show-hide-container_arrow_up {
  transform: rotate(-45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 12px;
  border-width: 1.5px 0px 0px 1.5px !important;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.theme-hnonline .table-container_header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #e5f2f6;
  height: 44px;
  border-radius: 5px 5px 0px 0px;
  padding-left: 48px;
}
@media (max-width: 959px) {
  .theme-hnonline .table-container_header {
    padding-left: 10px;
  }
}
.theme-hnonline .table-container_header .head {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
.theme-hnonline .table-container_header .last_head {
  width: 50%;
}
.theme-hnonline .table-container_body {
  display: flex;
  flex-direction: column;
}
.theme-hnonline .table-container_body_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  height: 43px;
  align-items: center;
  border-bottom: 1px solid #00000029;
  padding-left: 48px;
}
@media (max-width: 959px) {
  .theme-hnonline .table-container_body_row {
    padding-left: 10px;
  }
}
.theme-hnonline .table-container_body_row .cell {
  width: 100%;
  padding-right: 20px;
}
.theme-hnonline .table-container_body_row .last_cell {
  width: 50%;
}
.theme-hnonline .table-container_body .last_row {
  border-radius: 0px 0px 5px 5px;
}
.theme-hnonline .table-container .edit_button {
  color: #217a9c;
  text-decoration: underline;
  cursor: pointer;
}
.theme-hnonline .table-container .page-icon {
  color: "red";
  border-radius: 50%;
  border: 1px solid;
}
.theme-hnonline .table-container .MuiPaper-root {
  border-radius: 10px !important;
}
.theme-hnonline .table-container .MuiTableCell-root span svg {
  opacity: 1;
}
.theme-hnonline .table-container .MuiTableSortLabel-root.MuiTableSortLabel-active {
  font-weight: bold;
}
.theme-hnonline .table-container .MuiPaper-elevation2 {
  box-shadow: unset !important;
}
.theme-hnonline .table-container .MuiTableRow-head {
  border-top: 1px solid #00000029;
}
.theme-hnonline .table-container .bottom-text {
  width: 100%;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.theme-hnonline .table-container .MuiTableFooter-root tr {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
}
.theme-hnonline .table-container .MuiTablePagination-input {
  display: none;
}
.theme-pulse .table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.theme-pulse .table-container_header {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  height: 44px;
  border-radius: 5px 5px 0px 0px;
  padding-left: 48px;
}
@media (max-width: 959px) {
  .theme-pulse .table-container_header {
    padding-left: 10px;
  }
}
.theme-pulse .table-container_header .head {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
}
.theme-pulse .table-container_header .last_head {
  width: 50%;
}
.theme-pulse .table-container_body {
  display: flex;
  flex-direction: column;
}
.theme-pulse .table-container_body_row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #fff;
  height: 43px;
  align-items: center;
  border-bottom: 1px solid;
  padding-left: 48px;
}
@media (max-width: 959px) {
  .theme-pulse .table-container_body_row {
    padding-left: 10px;
  }
}
.theme-pulse .table-container_body_row .cell {
  width: 100%;
  padding-right: 20px;
}
.theme-pulse .table-container_body_row .last_cell {
  width: 50%;
}
.theme-pulse .table-container_body .last_row {
  border-radius: 0px 0px 5px 5px;
}
.theme-pulse .table-container .edit_button {
  color: #217a9c;
  text-decoration: underline;
  cursor: pointer;
}
.theme-pulse .table-container .page-icon {
  color: "red";
  border-radius: 50%;
  border: 1px solid;
}
.theme-pulse .table-container .MuiPaper-root {
  border-radius: 10px !important;
}
.theme-pulse .table-container .MuiTableCell-root span svg {
  opacity: 1;
}
.theme-pulse .table-container .MuiTableSortLabel-root.MuiTableSortLabel-active {
  font-weight: bold;
}
.theme-pulse .table-container .MuiPaper-elevation2 {
  box-shadow: unset !important;
}
.theme-pulse .table-container .MuiTableRow-head {
  border-top: 1px solid #00000029;
}
.theme-pulse .table-container .bottom-text {
  width: 100%;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
}
.theme-pulse .table-container .MuiTableFooter-root tr {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 24px;
}
.theme-pulse .table-container .MuiTablePagination-input {
  display: none;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
@media screen and (max-width: 600px) {
  .search-bar {
    margin: 0;
    width: 100%;
  }
}
.theme-hnonline .search-bar-box {
  border: 0.5px solid #C7C7C7;
  background: #fff;
  width: 450px;
  height: 36px;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .theme-hnonline .search-bar-box {
    width: 250px;
  }
}
.theme-hnonline .search-bar-box .input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.theme-hnonline .search-bar-box .input-container svg {
  margin-right: 10px;
  color: #007FA3;
}
.theme-hnonline .search-bar-box input {
  border: 0px;
  height: 34px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 18px;
  color: #252525;
  width: 90%;
}
.theme-hnonline .search-bar-box input:focus {
  outline: 0;
}
.theme-pulse .search-bar-box {
  border: 0.5px solid #C7C7C7;
  background: #fff;
  width: 450px;
  height: 36px;
  border-radius: 4px;
}
@media (max-width: 959px) {
  .theme-pulse .search-bar-box {
    width: 250px;
  }
}
.theme-pulse .search-bar-box .input-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.theme-pulse .search-bar-box .input-container svg {
  margin-right: 10px;
  color: #007FA3;
}
.theme-pulse .search-bar-box input {
  border: 0px;
  height: 34px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 18px;
  width: 90%;
}
.theme-pulse .search-bar-box input:focus {
  outline: 0;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .discussion-list {
  display: flex;
  width: 100%;
  height: 100%;
  background: #d9e6f1;
}
.theme-hnonline .discussion-list_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 55px 155px 88px 155px;
  background-color: #fff;
  padding: opx 0px 24px 0px;
  border-radius: 10px;
}
@media (max-width: 959px) {
  .theme-hnonline .discussion-list_container {
    margin: 20px 10px 0px 10px;
  }
}
.theme-hnonline .discussion-list_container_title {
  height: 60px;
  border-bottom: 1px solid #6a707033;
  padding: 0px 24px 0px 24px;
}
.theme-hnonline .discussion-list_container_title p {
  font-size: 18px;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
  color: #007FA3;
  padding: 20px 0;
}
.theme-hnonline .discussion-list_container_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
  padding: 0px 24px 0px 24px;
}
.theme-hnonline .discussion-list_container_button .button {
  display: flex;
  width: 128px;
  height: 32px;
  background: #047A9C 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  padding: 0px;
}
.theme-hnonline .discussion-list_container_table {
  display: flex;
  margin-top: 20px;
}
.theme-pulse .discussion-list {
  display: flex;
  width: 100%;
  height: 100%;
}
.theme-pulse .discussion-list_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 55px 155px 88px 155px;
  background-color: #fff;
  padding: opx 0px 24px 0px;
  border-radius: 10px;
}
@media (max-width: 959px) {
  .theme-pulse .discussion-list_container {
    margin: 20px 10px 0px 10px;
  }
}
.theme-pulse .discussion-list_container_title {
  height: 60px;
  border-bottom: 1px solid;
  padding: 0px 24px 0px 24px;
}
.theme-pulse .discussion-list_container_title p {
  font-size: 18px;
  letter-spacing: 0px;
  font-family: "Open Sans", sans-serif;
  color: #007FA3;
  padding: 20px 0;
}
.theme-pulse .discussion-list_container_button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 22px;
  padding: 0px 24px 0px 24px;
}
.theme-pulse .discussion-list_container_button .button {
  display: flex;
  width: 128px;
  height: 32px;
  background: #047A9C 0% 0% no-repeat padding-box;
  border-radius: 50px;
  opacity: 1;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  padding: 0px;
}
.theme-pulse .discussion-list_container_table {
  display: flex;
  margin-top: 20px;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .wrapper {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  color: #252525;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
@media (max-width: 1160px) {
  .theme-hnonline .wrapper {
    display: block;
  }
}
.theme-hnonline .wrapper__content {
  background: #d9e6f1;
  display: flex;
  flex: 1 1;
}
@media (max-width: 1160px) {
  .theme-hnonline .wrapper__content {
    min-height: calc(100% - 60px);
  }
}
.theme-pulse .wrapper {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
@media (max-width: 1160px) {
  .theme-pulse .wrapper {
    display: block;
  }
}
.theme-pulse .wrapper__content {
  display: flex;
  flex: 1 1;
}
@media (max-width: 1160px) {
  .theme-pulse .wrapper__content {
    min-height: calc(100% - 60px);
  }
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .logo_wrap {
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.theme-hnonline .logo_wrap img {
  margin-top: 6px;
}
@media (max-width: 1160px) {
  .theme-hnonline .logo_wrap img {
    max-width: 192px;
    margin-top: 6px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .logo_wrap img {
    margin-top: 2px;
  }
}
.theme-hnonline .logo_wrap_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.theme-hnonline .logo_wrap_logo span {
  font-size: 16px;
  font-weight: Bold;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 10px;
}
.theme-hnonline .logo_wrap__text {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
  padding-top: 11px;
  padding-left: 8px;
  color: #000000;
  text-decoration: none;
}
.theme-pulse .logo_wrap {
  max-width: 250px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
}
.theme-pulse .logo_wrap img {
  margin-top: 6px;
}
@media (max-width: 1160px) {
  .theme-pulse .logo_wrap img {
    max-width: 192px;
    margin-top: 6px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .logo_wrap img {
    margin-top: 2px;
  }
}
.theme-pulse .logo_wrap_logo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.theme-pulse .logo_wrap_logo span {
  font-size: 16px;
  font-weight: Bold;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0px;
  color: #000000;
  margin-left: 10px;
}
.theme-pulse .logo_wrap__text {
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  font-size: 16px;
  font-weight: bold;
  padding-top: 11px;
  padding-left: 8px;
  text-decoration: none;
}
/*
 * Theme definitions
 */
/*
* Implementation of themes
*/
.theme-hnonline .header {
  padding: 0px 55px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px #e4e8f1;
}
@media (max-width: 1160px) {
  .theme-hnonline .header {
    height: 60px;
    padding: 0 52px;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .header {
    justify-content: flex-start;
    padding: 0 8px 0 19px;
  }
}
.theme-hnonline .header_right {
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 17px;
  min-width: 265px;
}
.theme-hnonline .header_right__user-menu {
  z-index: 9999;
}
.theme-hnonline .header_right__user-pointer {
  pointer-events: none;
}
@media (max-width: 1160px) {
  .theme-hnonline .header_right {
    padding-top: 12px;
    min-width: auto;
  }
}
@media (max-width: 959px) {
  .theme-hnonline .header_right {
    margin-left: auto;
    min-width: auto;
  }
}
.theme-hnonline .header_right_help {
  margin: 0 23px 0 15px;
}
@media (max-width: 959px) {
  .theme-hnonline .header_right_help {
    margin: 0 10px;
  }
}
.theme-hnonline .header__user-menu {
  z-index: 9999;
}
.theme-pulse .header {
  padding: 0px 55px;
  height: 70px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px;
}
@media (max-width: 1160px) {
  .theme-pulse .header {
    height: 60px;
    padding: 0 52px;
  }
}
@media (max-width: 959px) {
  .theme-pulse .header {
    justify-content: flex-start;
    padding: 0 8px 0 19px;
  }
}
.theme-pulse .header_right {
  display: flex;
  align-self: flex-start;
  align-items: center;
  padding-top: 17px;
  min-width: 265px;
}
.theme-pulse .header_right__user-menu {
  z-index: 9999;
}
.theme-pulse .header_right__user-pointer {
  pointer-events: none;
}
@media (max-width: 1160px) {
  .theme-pulse .header_right {
    padding-top: 12px;
    min-width: auto;
  }
}
@media (max-width: 959px) {
  .theme-pulse .header_right {
    margin-left: auto;
    min-width: auto;
  }
}
.theme-pulse .header_right_help {
  margin: 0 23px 0 15px;
}
@media (max-width: 959px) {
  .theme-pulse .header_right_help {
    margin: 0 10px;
  }
}
.theme-pulse .header__user-menu {
  z-index: 9999;
}
